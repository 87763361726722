import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  PLAY_SINGLE_OUT,
  PLAY_DOUBLE_OUT,
  PLAY_DOUBLE_IN_DOUBLE_OUT,
  START_301,
  START_501,
  START_701,
  START_901,
  GAME_SETTING,
  RESET_INIT
} from './dartsReducer';


const Start = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { initComplete, noPlayerError, players } = useSelector( state => state.darts.present );


  useEffect( () => {
    if( initComplete ) {
      dispatch( { type: RESET_INIT } );
      history.push( "/play" );
    }
  }, [dispatch, history, initComplete] );

  const MALE_1 = "MALE_1";
  const MALE_2 = "MALE_2";
  const FEMALE_1 = "FEMALE_1";
  const FEMALE_2 = "FEMALE_2";
  const [player1, setPlayer1] = useState( players && players[0] && players[0].name ? players[0].name : null );
  const [player2, setPlayer2] = useState( players && players[1] && players[1].name ? players[1].name : null );
  const [player3, setPlayer3] = useState( players && players[2] && players[2].name ? players[2].name : null );
  const [player4, setPlayer4] = useState( players && players[3] && players[3].name ? players[3].name : null );
  const [player1Avatar, setPlayer1Avatar] = useState( players && players[0] && players[0].avatar ? players[0].avatar : MALE_1 );
  const [player2Avatar, setPlayer2Avatar] = useState( players && players[1] && players[1].avatar ? players[1].avatar : MALE_1 );
  const [player3Avatar, setPlayer3Avatar] = useState( players && players[2] && players[2].avatar ? players[2].avatar : MALE_1 );
  const [player4Avatar, setPlayer4Avatar] = useState( players && players[3] && players[3].avatar ? players[3].avatar : MALE_1 );
  const [startValue, setStartValue] = useState( START_501 );
  const [playMethod, setPlayMethod] = useState( PLAY_DOUBLE_OUT );


  const getStyleSex = ( type, playerAvatar ) => {

    let backgroundColor;
    let marginLeft;
    if( type === MALE_1 ) {
      backgroundColor = playerAvatar === MALE_1 ? "#785c32" : ""
      marginLeft = "0px"
    }
    else if( type === MALE_2 ) {
      backgroundColor = playerAvatar === MALE_2 ? "#785c32" : ""
      marginLeft = "10px"
    }
    else if( type === FEMALE_1 ) {
      backgroundColor = playerAvatar === FEMALE_1 ? "#785c32" : "";
      marginLeft = "10px";
    }
    else if( type === FEMALE_2 ) {
      backgroundColor = playerAvatar === FEMALE_2 ? "#785c32" : "";
      marginLeft = "10px";
    }

    return {
      float: "left",
      marginLeft: marginLeft,
      cursor: "pointer",
      backgroundColor: backgroundColor
    };
  }


  const doSetGameSetting = ( value ) => {
    dispatch( {
      type: GAME_SETTING,
      player1: player1,
      player1Avatar: player1Avatar,
      player2: player2,
      player2Avatar: player2Avatar,
      player3: player3,
      player3Avatar: player3Avatar,
      player4: player4,
      player4Avatar: player4Avatar,
      startValue: startValue,
      playMethod: playMethod
    } );
  };


  return (

    <div className="center800">

      <img style={{
        minWidth: "300px",
        maxWidth: "500px",
        height: "auto",
        width: "100%"
      }} draggable="false" src="images/darts_counter.png" alt="undo"/>

      <div className="welcomeText">
        Hallo und herzlich willkommen.
        <br/>
        Gebe bis zu vier Spieler an und du kannst sofort mit dem spielen beginnen
      </div>
      {noPlayerError && <div>
        <h1>Gebe mindestens einen Spieler an</h1>
      </div>}

      <div className="startLeft">

        <div className="playerName">
          Spieler&nbsp;1:
        </div>
        <div className="iconSelectSmall">
          <div style={getStyleSex( MALE_1, player1Avatar )} onClick={() => setPlayer1Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player1Avatar )} onClick={( e ) => setPlayer1Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player1Avatar )} onClick={() => setPlayer1Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player1Avatar )} onClick={( e ) => setPlayer1Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div className="clear"></div>
        <div className="playerInput">
          <input className="playerNameInput" type="text"
                 value={player1 ? player1 : ""}
                 onChange={( e ) => {
                   setPlayer1( e.target.value )
                 }}/>
        </div>
        <div className="iconSelect">
          <div style={getStyleSex( MALE_1, player1Avatar )} onClick={() => setPlayer1Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player1Avatar )} onClick={( e ) => setPlayer1Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player1Avatar )} onClick={() => setPlayer1Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player1Avatar )} onClick={( e ) => setPlayer1Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div style={{ clear: "both" }}>
        </div>


        <div className="playerName">
          Spieler&nbsp;2:
        </div>
        <div className="iconSelectSmall">
          <div style={getStyleSex( MALE_1, player2Avatar )} onClick={() => setPlayer2Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player2Avatar )} onClick={( e ) => setPlayer2Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player2Avatar )} onClick={() => setPlayer2Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player2Avatar )} onClick={( e ) => setPlayer2Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div className="clear"></div>
        <div className="playerInput">
          <input className="playerNameInput" type="text"
                 value={player2 ? player2 : ""}
                 onChange={( e ) => {
                   setPlayer2( e.target.value )
                 }}/>
        </div>
        <div className="iconSelect">
          <div style={getStyleSex( MALE_1, player2Avatar )} onClick={() => setPlayer2Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player2Avatar )} onClick={( e ) => setPlayer2Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player2Avatar )} onClick={() => setPlayer2Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player2Avatar )} onClick={( e ) => setPlayer2Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div style={{ clear: "both" }}>
        </div>


        <div className="playerName">
          Spieler&nbsp;3:
        </div>
        <div className="iconSelectSmall">
          <div style={getStyleSex( MALE_1, player3Avatar )} onClick={() => setPlayer3Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player3Avatar )} onClick={( e ) => setPlayer3Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player3Avatar )} onClick={() => setPlayer3Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player3Avatar )} onClick={( e ) => setPlayer3Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div className="clear"></div>
        <div className="playerInput">
          <input className="playerNameInput" type="text"
                 value={player3 ? player3 : ""}
                 onChange={( e ) => {
                   setPlayer3( e.target.value )
                 }}/>
        </div>
        <div className="iconSelect">
          <div style={getStyleSex( MALE_1, player3Avatar )} onClick={() => setPlayer3Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player3Avatar )} onClick={( e ) => setPlayer3Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player3Avatar )} onClick={() => setPlayer3Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player3Avatar )} onClick={( e ) => setPlayer3Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div style={{ clear: "both" }}>
        </div>


        <div className="playerName">
          Spieler&nbsp;4:
        </div>
        <div className="iconSelectSmall">
          <div style={getStyleSex( MALE_1, player4Avatar )} onClick={() => setPlayer4Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player4Avatar )} onClick={( e ) => setPlayer4Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player4Avatar )} onClick={() => setPlayer4Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player4Avatar )} onClick={( e ) => setPlayer4Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div className="clear"></div>
        <div className="playerInput">
          <input className="playerNameInput" type="text"
                 value={player4 ? player4 : ""}
                 onChange={( e ) => {
                   setPlayer4( e.target.value )
                 }}/>
        </div>
        <div className="iconSelect">
          <div style={getStyleSex( MALE_1, player4Avatar )} onClick={() => setPlayer4Avatar( MALE_1 )}>
            <img draggable="false" src="images/man_1.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_1, player4Avatar )} onClick={( e ) => setPlayer4Avatar( FEMALE_1 )}>
            <img draggable="false" src="images/woman_1.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={getStyleSex( MALE_2, player4Avatar )} onClick={() => setPlayer4Avatar( MALE_2 )}>
            <img draggable="false" src="images/man_2.png" align="top" alt="man" title="männlich" width="30px"/>
          </div>
          <div style={getStyleSex( FEMALE_2, player4Avatar )} onClick={( e ) => setPlayer4Avatar( FEMALE_2 )}>
            <img draggable="false" src="images/woman_2.png" align="top" alt="woman" title="weiblich" width="30px"/>
          </div>
          <div style={{ clear: "both" }}>
          </div>
        </div>
        <div style={{ clear: "both" }}>
        </div>


      </div>
      <div className="startRight">
        <table>
          <thead>
          <tr>
            <td>Start</td>
            <td>
              <select className="start-form-select" name="startValue" defaultValue={startValue}
                      onChange={( e ) => {
                        setStartValue( e.target.value );
                      }}>
                <option value={START_301}>301</option>
                <option value={START_501}>501</option>
                <option value={START_701}>701</option>
                <option value={START_901}>901</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Modus</td>
            <td>
              <select className="start-form-select" name="outMethod" onChange={( e ) => {
                setPlayMethod( e.target.value );
              }} defaultValue={playMethod}>
                <option value={PLAY_SINGLE_OUT}>Single-Out</option>
                <option value={PLAY_DOUBLE_OUT}>Double-Out</option>
                <option value={PLAY_DOUBLE_IN_DOUBLE_OUT}>Double-In-Double-Out</option>
              </select>
            </td>
          </tr>
          </thead>
        </table>
      </div>
      <div style={{ clear: "both" }}>
      </div>

      <div className="actionButton" type="button" onClick={doSetGameSetting}>
        Spiel starten
      </div>

    </div>);
}

export default Start;