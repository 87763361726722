import cloneDeep from 'lodash/cloneDeep';

export const START_301 = 301;
export const START_501 = 501;
export const START_701 = 701;
export const START_901 = 901;

export const PLAY_DOUBLE_OUT = "PLAY_DOUBLE_OUT";
export const PLAY_SINGLE_OUT = "PLAY_SINGLE_OUT";
export const PLAY_DOUBLE_IN_DOUBLE_OUT = "PLAY_DOUBLE_IN_DOUBLE_OUT";

export const GAME_SETTING = "GAME_SETTING";
export const RESET_INIT = "RESET_INIT";
export const THROW = "THROW";
export const TOGGLE_DOUBLE = "TOGGLE_DOUBLE";
export const TOGGLE_TRIPLE = "TOGGLE_TRIPLE";
export const DARTS_UNDO = 'DARTS_UNDO';


class Player {
  constructor() {
    this.name = null;
    this.avatar = null;
    this.currentThrowCount = 0;
    this.doubleThrowCount = 0;
    this.tripleThrowCount = 0;
    this.gameThrowCount = 0;
    this.firstThrow = null;
    this.secondThrow = null;
    this.thirdThrow = null;
    this.sumLastThrow = null;
    this.sum = 0;
    this.rest = null;
    this.overThrown = false;
    this.winner = false;
    this.gamesWon = 0;
    this.isInGame = true;
    this.finishWay = null;
  }
}


const initialState = {
  start: null,
  playMethod: null,
  double: false,
  triple: false,
  players: null,
  initComplete: false,
  noPlayerError: false,
  currentPlayer: 0,
  gameFinished: false
};


const dartsReducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case RESET_INIT: {
      return {
        ...state,
        initComplete: false
      }
    }
    case GAME_SETTING: {
      const players = [];

      if( action.player1 ) {
        const player = new Player();
        player.name = action.player1;
        player.rest = action.startValue;
        player.avatar = action.player1Avatar;
        player.gamesWon = action.player1won ? action.player1won : 0;
        if( action.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT ) {
          player.isInGame = false;
        }
        players.push( player );
      }
      if( action.player2 ) {
        const player = new Player();
        player.name = action.player2;
        player.rest = action.startValue;
        player.avatar = action.player2Avatar;
        player.gamesWon = action.player2won ? action.player2won : 0;
        if( action.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT ) {
          player.isInGame = false;
        }
        players.push( player );
      }
      if( action.player3 ) {
        const player = new Player();
        player.name = action.player3;
        player.rest = action.startValue;
        player.avatar = action.player3Avatar;
        player.gamesWon = action.player3won ? action.player3won : 0;
        if( action.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT ) {
          player.isInGame = false;
        }
        players.push( player );
      }
      if( action.player4 ) {
        const player = new Player();
        player.name = action.player4;
        player.rest = action.startValue;
        player.avatar = action.player4Avatar;
        player.gamesWon = action.player4won ? action.player4won : 0;
        if( action.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT ) {
          player.isInGame = false;
        }
        players.push( player );
      }

      if( players.length === 0 ) {
        return {
          ...state,
          noPlayerError: true
        }
      }

      return {
        ...state,
        playMethod: action.playMethod,
        start: action.startValue,
        players: players,
        noPlayerError: false,
        initComplete: true,
        gameFinished: false,
        currentPlayer: 0
      }
    }
    case TOGGLE_DOUBLE: {
      return {
        ...state,
        double: !state.double,
        triple: false
      }
    }
    case TOGGLE_TRIPLE: {
      return {
        ...state,
        triple: !state.triple,
        double: false
      }
    }
    case THROW: {
      const newState = cloneDeep( state );

      let thrown = newState.double ? action.value * 2 : newState.triple ? action.value * 3 : action.value;

      if( !newState.players[newState.currentPlayer].isInGame && newState.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT ) {
        if( !newState.double ) {
          thrown = 0;
        }
        else {
          newState.players[newState.currentPlayer].isInGame = true;
        }
      }


      let rest = newState.players[newState.currentPlayer].rest - thrown;
      newState.players[newState.currentPlayer].gameThrowCount = newState.players[newState.currentPlayer].gameThrowCount + 1;

      if( newState.double ) {
        newState.players[newState.currentPlayer].doubleThrowCount = newState.players[newState.currentPlayer].doubleThrowCount + 1;
      }
      if( newState.triple ) {
        newState.players[newState.currentPlayer].tripleThrowCount = newState.players[newState.currentPlayer].tripleThrowCount + 1;
      }


      if( newState.players[newState.currentPlayer].currentThrowCount === 3 ) {
        resetCurrentPlayer( newState );
      }


      if( rest === 0 ) {
        if( !((newState.playMethod === PLAY_DOUBLE_OUT || newState.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT) && !newState.double) ) {
          doFinish( thrown, newState );
          return newState;
        }
      }


      if( (newState.playMethod === PLAY_DOUBLE_OUT || newState.playMethod === PLAY_DOUBLE_IN_DOUBLE_OUT) && rest === 1 ) {

        doOverthrow( thrown, newState );
        doNextPlayerIfMoreThanOne( newState );
        return newState;
      }

      if( rest <= 0 ) {
        doOverthrow( thrown, newState );
        doNextPlayerIfMoreThanOne( newState );
        return newState;

      }

      let currentThrowCount;


      if( newState.players[newState.currentPlayer].overThrown ) {
        newState.players[newState.currentPlayer].overThrown = false;
      }


      if( newState.players[newState.currentPlayer].currentThrowCount === 0 ) {
        newState.players[newState.currentPlayer].firstThrow = thrown;
        currentThrowCount = newState.players[newState.currentPlayer].currentThrowCount + 1;
      }
      if( newState.players[newState.currentPlayer].currentThrowCount === 1 ) {
        newState.players[newState.currentPlayer].secondThrow = thrown;
        currentThrowCount = newState.players[newState.currentPlayer].currentThrowCount + 1;
      }
      else if( newState.players[newState.currentPlayer].currentThrowCount === 2 ) {
        newState.players[newState.currentPlayer].thirdThrow = thrown;
        currentThrowCount = newState.players[newState.currentPlayer].currentThrowCount + 1;
      }

      newState.players[newState.currentPlayer].currentThrowCount = currentThrowCount;
      newState.players[newState.currentPlayer].sum = newState.players[newState.currentPlayer].sum + thrown;
      newState.players[newState.currentPlayer].rest = rest;


      const throwCount = newState.players[newState.currentPlayer].currentThrowCount;
      let dartsLeft = throwCount === 0 || throwCount === 3 ? 3 : throwCount === 1 ? 2 : 1;
      newState.players[newState.currentPlayer].finishWay = getFinishWay( rest, dartsLeft );

      newState.double = false;
      newState.triple = false;

      doNextPlayerIfMoreThanOne( newState );



      return newState;
    }
    default: {
      return state;
    }
  }


  function doFinish( thrown, newState ) {


    let firstThrow = newState.players[newState.currentPlayer].firstThrow;
    let secondThrow = newState.players[newState.currentPlayer].secondThrow;

    if( firstThrow && secondThrow ) {
      newState.players[newState.currentPlayer].thirdThrow = thrown;
    }
    else if( firstThrow ) {
      newState.players[newState.currentPlayer].secondThrow = thrown;
    }
    else {
      newState.players[newState.currentPlayer].thirdThrow = thrown;
    }
    newState.players[newState.currentPlayer].winner = true;
    newState.players[newState.currentPlayer].gamesWon = newState.players[newState.currentPlayer].gamesWon + 1;
    newState.players[newState.currentPlayer].rest = 0;
    newState.gameFinished = true;
    newState.triple = false;
    newState.double = false;

  }


  function doOverthrow( thrown, newState ) {
    newState.players[newState.currentPlayer].overThrown = true;
    newState.players[newState.currentPlayer].currentThrowCount = 3;


    let sumAllThrows = 0;
    let firstThrow = newState.players[newState.currentPlayer].firstThrow;
    let secondThrow = newState.players[newState.currentPlayer].secondThrow;

    if( firstThrow && secondThrow ) {

      sumAllThrows = firstThrow + secondThrow;
      newState.players[newState.currentPlayer].thirdThrow = thrown;
    }
    else if( firstThrow ) {
      sumAllThrows = firstThrow;
      newState.players[newState.currentPlayer].secondThrow = thrown;
    }
    else {
      newState.players[newState.currentPlayer].thirdThrow = thrown;
    }


    newState.players[newState.currentPlayer].rest = newState.players[newState.currentPlayer].rest + sumAllThrows;
    newState.players[newState.currentPlayer].sum = newState.players[newState.currentPlayer].sum + thrown;
    newState.players[newState.currentPlayer].finishWay = getFinishWay( newState.players[newState.currentPlayer].rest, 3 );

    newState.triple = false;
    newState.double = false;
  }

  function doNextPlayerIfMoreThanOne( newState ) {
    if( newState.players[newState.currentPlayer].currentThrowCount === 3 && newState.players.length > 1 ) {
      if( newState.players.length - 1 === newState.currentPlayer ) {
        newState.currentPlayer = 0;
      }
      else {
        newState.currentPlayer = newState.currentPlayer + 1;
      }
      resetCurrentPlayer( newState );
    }
  }

  function resetCurrentPlayer( newState ) {
    newState.players[newState.currentPlayer].currentThrowCount = 0;
    newState.players[newState.currentPlayer].sum = 0;
    newState.players[newState.currentPlayer].sumLastThrow = newState.players[newState.currentPlayer].firstThrow + newState.players[newState.currentPlayer].secondThrow + newState.players[newState.currentPlayer].thirdThrow;
    newState.players[newState.currentPlayer].firstThrow = null;
    newState.players[newState.currentPlayer].secondThrow = null;
    newState.players[newState.currentPlayer].thirdThrow = null;
    newState.players[newState.currentPlayer].overThrown = false;
  }


  function getFinishWay( rest, darts ) {

    if( rest > 170 || rest < 2 ) {
      return null;
    }
    if( rest === 170 && darts === 3 ) {
      return "T20 - T20 - DB";
    }
    if( rest === 167 && darts === 3 ) {
      return "T20 - T19 - DB";
    }
    if( rest === 164 && darts === 3 ) {
      return "T20 - T18 - DB"
    }
    if( rest === 161 && darts === 3 ) {
      return "T20 - T17 - DB"
    }
    if( rest === 160 && darts === 3 ) {
      return "T20 - T20 - D20"
    }
    if( rest === 158 && darts === 3 ) {
      return "T20 - T20 - D19"
    }
    if( rest === 157 && darts === 3 ) {
      return "T20 - T19 - D20"
    }
    if( rest === 156 && darts === 3 ) {
      return "T20 - T20 - D18"
    }
    if( rest === 155 && darts === 3 ) {
      return "T20 - T19 - D19"
    }
    if( rest === 154 && darts === 3 ) {
      return "T20 - T18 - D20"
    }
    if( rest === 153 && darts === 3 ) {
      return "T20 - T19 - D18"
    }
    if( rest === 152 && darts === 3 ) {
      return "T20 - T20 - D16"
    }
    if( rest === 151 && darts === 3 ) {
      return "T20 - T17 - D20"
    }
    if( rest === 150 && darts === 3 ) {
      return "T20 - T20 - D15"
    }
    if( rest === 149 && darts === 3 ) {
      return "T20 - T19 - D16"
    }
    if( rest === 148 && darts === 3 ) {
      return "T20 - T20 - D14"
    }
    if( rest === 147 && darts === 3 ) {
      return "T20 - T17 - D18"
    }
    if( rest === 146 && darts === 3 ) {
      return "T20 - T18 - D16"
    }
    if( rest === 145 && darts === 3 ) {
      return "T20 - T15 - D20"
    }
    if( rest === 144 && darts === 3 ) {
      return "T20 - T20 - D12"
    }
    if( rest === 143 && darts === 3 ) {
      return "T20 - T17 - D16"
    }
    if( rest === 142 && darts === 3 ) {
      return "T20 - T14 - D20"
    }
    if( rest === 141 && darts === 3 ) {
      return "T20 - T19 - D12"
    }
    if( rest === 140 && darts === 3 ) {
      return "T20 - T20 - D10"
    }
    if( rest === 139 && darts === 3 ) {
      return "T20 - T19 - D11"
    }
    if( rest === 138 && darts === 3 ) {
      return "T20 - T18 - D12"
    }
    if( rest === 137 && darts === 3 ) {
      return "T20 - T19 - D10"
    }
    if( rest === 136 && darts === 3 ) {
      return "T20 - T20 - D8"
    }
    if( rest === 135 && darts === 3 ) {
      return "T20 - T17 - D12"
    }
    if( rest === 134 && darts === 3 ) {
      return "T20 - T14 - D16"
    }
    if( rest === 133 && darts === 3 ) {
      return "T20 - T19 - D8"
    }
    if( rest === 132 && darts === 3 ) {
      return "T20 - T16 - D12"
    }
    if( rest === 131 && darts === 3 ) {
      return "T20 - T13 - D16"
    }
    if( rest === 130 && darts === 3 ) {
      return "T20 - T18 - D8"
    }
    if( rest === 129 && darts === 3 ) {
      return "T19 - T16 - D12"
    }
    if( rest === 128 && darts === 3 ) {
      return "T18 - T14 - D16"
    }
    if( rest === 127 && darts === 3 ) {
      return "T20 - T9 - D20"
    }
    if( rest === 126 && darts === 3 ) {
      return "T19 - T19 - D6"
    }
    if( rest === 125 && darts === 3 ) {
      return "T18 - T13 - D16"
    }
    if( rest === 124 && darts === 3 ) {
      return "T20 - T14 - D11"
    }
    if( rest === 123 && darts === 3 ) {
      return "T19 - T16 - D9"
    }
    if( rest === 122 && darts === 3 ) {
      return "T18 - T18 - D7"
    }
    if( rest === 121 && darts === 3 ) {
      return "T20 - T11 - D14"
    }
    if( rest === 120 && darts === 3 ) {
      return "T20 - 20 - D20"
    }
    if( rest === 119 && darts === 3 ) {
      return "T19 - T12 - D13"
    }
    if( rest === 118 && darts === 3 ) {
      return "T20 - 18 - D20"
    }
    if( rest === 117 && darts === 3 ) {
      return "T19 - 20 - D20"
    }
    if( rest === 116 && darts === 3 ) {
      return "T19 - 19 - D20"
    }
    if( rest === 115 && darts === 3 ) {
      return "T20 - 15 - D20"
    }
    if( rest === 114 && darts === 3 ) {
      return "T19 - 17 - D20"
    }
    if( rest === 113 && darts === 3 ) {
      return "T19 - 16 - D20"
    }
    if( rest === 112 && darts === 3 ) {
      return "T20 - 12 - D20"
    }
    if( rest === 111 && darts === 3 ) {
      return "T19 - 14 - D20"
    }
    if( rest === 110 && darts >= 2 ) {
      return "T20 - DB"
    }
    if( rest === 109 && darts === 3 ) {
      return "T20 - 9 - D20"
    }
    if( rest === 108 && darts === 3 ) {
      return "T20 - 16 - D16"
    }
    if( rest === 107 && darts >= 2 ) {
      return "T19 - DB"
    }
    if( rest === 106 && darts === 3 ) {
      return "T20 - 6 - D20"
    }
    if( rest === 105 && darts === 3 ) {
      return "T20 - 5 - D20"
    }
    if( rest === 104 && darts >= 2 ) {
      return "T18 - DB"
    }
    if( rest === 103 && darts === 3 ) {
      return "T19 - 6 - D20"
    }
    if( rest === 102 && darts === 3 ) {
      return "T20 - 2 - D20"
    }
    if( rest === 101 && darts >= 2 ) {
      return "T17 - DB"
    }
    if( rest === 100 && darts >= 2 ) {
      return "T20 - D20"
    }
    if( rest === 99 && darts === 3 ) {
      return "T19 - 10 - D16"
    }
    if( rest === 98 && darts >= 2 ) {
      return "T20 - D19"
    }
    if( rest === 97 && darts >= 2 ) {
      return "T19 - D20"
    }
    if( rest === 96 && darts >= 2 ) {
      return "T20 - D18"
    }
    if( rest === 95 && darts >= 2 ) {
      return "T19 - D19"
    }
    if( rest === 94 && darts >= 2 ) {
      return "T18 - D20"
    }
    if( rest === 93 && darts >= 2 ) {
      return "T19 - D18"
    }
    if( rest === 92 && darts >= 2 ) {
      return "T20 - D16"
    }
    if( rest === 91 && darts >= 2 ) {
      return "T17 - D20"
    }
    if( rest === 90 && darts >= 2 ) {
      return "T20 - D15"
    }
    if( rest === 89 && darts >= 2 ) {
      return "T19 - D16"
    }
    if( rest === 88 && darts >= 2 ) {
      return "T20 - D14"
    }
    if( rest === 87 && darts >= 2 ) {
      return "T17 - D18"
    }
    if( rest === 86 && darts >= 2 ) {
      return "T18 - D16"
    }
    if( rest === 85 && darts >= 2 ) {
      return "T15 - D20"
    }
    if( rest === 84 && darts >= 2 ) {
      return "T20 - D12"
    }
    if( rest === 83 && darts >= 2 ) {
      return "T17 - D16"
    }
    if( rest === 82 && darts >= 2 ) {
      return "T14 - D20"
    }
    if( rest === 81 && darts >= 2 ) {
      return "T19 - D12"
    }
    if( rest === 80 && darts >= 2 ) {
      return "T20 - D10"
    }
    if( rest === 79 && darts >= 2 ) {
      return "T19 - D11"
    }
    if( rest === 78 && darts >= 2 ) {
      return "T18 - D12"
    }
    if( rest === 77 && darts >= 2 ) {
      return "T19 - D10"
    }
    if( rest === 76 && darts >= 2 ) {
      return "T20 - D8"
    }
    if( rest === 75 && darts >= 2 ) {
      return "T17 - D12"
    }
    if( rest === 74 && darts >= 2 ) {
      return "T14 - D16"
    }
    if( rest === 73 && darts >= 2 ) {
      return "T19 - D8"
    }
    if( rest === 72 && darts >= 2 ) {
      return "T16 - D12"
    }
    if( rest === 71 && darts >= 2 ) {
      return "T13 - D16"
    }
    if( rest === 70 && darts >= 2 ) {
      return "T18 - D8"
    }
    if( rest === 69 && darts >= 2 ) {
      return "T19 - D6"
    }
    if( rest === 68 && darts >= 2 ) {
      return "T18 - D7"
    }
    if( rest === 67 && darts >= 2 ) {
      return "T9 - D20"
    }
    if( rest === 66 && darts >= 2 ) {
      return "T16 - D9"
    }
    if( rest === 65 && darts >= 2 ) {
      return "T11 - D16"
    }
    if( rest === 64 && darts >= 2 ) {
      return "T14 - D11"
    }
    if( rest === 63 && darts >= 2 ) {
      return "T13 - D12"
    }
    if( rest === 62 && darts >= 2 ) {
      return "T12 - D13"
    }
    if( rest === 61 && darts >= 2 ) {
      return "T11 - D14"
    }
    if( rest === 60 && darts >= 2 ) {
      return "20 - D20"
    }
    if( rest === 59 && darts >= 2 ) {
      return "19 - D20"
    }
    if( rest === 58 && darts >= 2 ) {
      return "18 - D20"
    }
    if( rest === 57 && darts >= 2 ) {
      return "17 - D20"
    }
    if( rest === 56 && darts >= 2 ) {
      return "16 - D20"
    }
    if( rest === 55 && darts >= 2 ) {
      return "15 - D20"
    }
    if( rest === 54 && darts >= 2 ) {
      return "14 - D20"
    }
    if( rest === 53 && darts >= 2 ) {
      return "13 - D20"
    }
    if( rest === 52 && darts >= 2 ) {
      return "12 - D20"
    }
    if( rest === 51 && darts >= 2 ) {
      return "11 - D20"
    }
    if( rest === 50 && darts >= 2 ) {
      return "10 - D20"
    }
    if( rest === 49 && darts >= 2 ) {
      return "9 - D20"
    }
    if( rest === 48 && darts >= 2 ) {
      return "8 - D20"
    }
    if( rest === 47 && darts >= 2 ) {
      return "7 - D20"
    }
    if( rest === 46 && darts >= 2 ) {
      return "6 - D20"
    }
    if( rest === 45 && darts >= 2 ) {
      return "5 - D20"
    }
    if( rest === 44 && darts >= 2 ) {
      return "4 - D20"
    }
    if( rest === 43 && darts >= 2 ) {
      return "3 - D20"
    }
    if( rest === 42 && darts >= 2 ) {
      return "2 - D20"
    }
    if( rest === 41 && darts >= 2 ) {
      return "1 - D20"
    }
    if( rest === 40 && darts >= 1 ) {
      return "D20"
    }
    if( rest === 39 && darts >= 2 ) {
      return "1 - D19"
    }
    if( rest === 38 && darts >= 1 ) {
      return "D19"
    }
    if( rest === 37 && darts >= 2 ) {
      return "1 - D18"
    }
    if( rest === 36 && darts >= 1 ) {
      return "D18"
    }
    if( rest === 35 && darts >= 2 ) {
      return "1 - D17"
    }
    if( rest === 34 && darts >= 1 ) {
      return "D17"
    }
    if( rest === 33 && darts >= 2 ) {
      return "1 - D16"
    }
    if( rest === 32 && darts >= 1 ) {
      return "D16"
    }
    if( rest === 31 && darts >= 2 ) {
      return "1 - D15"
    }
    if( rest === 30 && darts >= 1 ) {
      return "D15"
    }
    if( rest === 29 && darts >= 2 ) {
      return "1 - D14"
    }
    if( rest === 28 && darts >= 1 ) {
      return "D14"
    }
    if( rest === 27 && darts >= 2 ) {
      return "1 - D13"
    }
    if( rest === 26 && darts >= 1 ) {
      return "D13"
    }
    if( rest === 25 && darts >= 2 ) {
      return "1 - D12"
    }
    if( rest === 24 && darts >= 1 ) {
      return "D12"
    }
    if( rest === 23 && darts >= 2 ) {
      return "1 - D11"
    }
    if( rest === 22 && darts >= 1 ) {
      return "D11"
    }
    if( rest === 21 && darts >= 2 ) {
      return "1 - D10"
    }
    if( rest === 20 && darts >= 1 ) {
      return "D10"
    }
    if( rest === 19 && darts >= 2 ) {
      return "1 - D9"
    }
    if( rest === 18 && darts >= 1 ) {
      return "D9"
    }
    if( rest === 17 && darts >= 2 ) {
      return "1 - D8"
    }
    if( rest === 16 && darts >= 1 ) {
      return "D8"
    }
    if( rest === 15 && darts >= 2 ) {
      return "1 - D7"
    }
    if( rest === 14 && darts >= 1 ) {
      return "D7"
    }
    if( rest === 13 && darts >= 2 ) {
      return "1 - D6"
    }
    if( rest === 12 && darts >= 1 ) {
      return "D6"
    }
    if( rest === 11 && darts >= 2 ) {
      return "1 - D5"
    }
    if( rest === 10 && darts >= 1 ) {
      return "D5"
    }
    if( rest === 9 && darts >= 2 ) {
      return "1 - D4"
    }
    if( rest === 8 && darts >= 1 ) {
      return "D4"
    }
    if( rest === 7 && darts >= 2 ) {
      return "1 - D3"
    }
    if( rest === 6 && darts >= 1 ) {
      return "D3"
    }
    if( rest === 5 && darts >= 2 ) {
      return "1 - D2"
    }
    if( rest === 4 && darts >= 1 ) {
      return "D2"
    }
    if( rest === 3 && darts >= 2 ) {
      return "1 - D1"
    }
    if( rest === 2 && darts >= 1 ) {
      return "D1"
    }
    return null;
  }

};

export default dartsReducer;