import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { GAME_SETTING, RESET_INIT } from './dartsReducer';
import cloneDeep from 'lodash/cloneDeep';


const Result = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { start, playMethod, players } = useSelector(state => state.darts.present);

  const [changeablePlayers, setChangeablePlayers] = useState(cloneDeep(players));

  useEffect(() => {
    if (!players || !playMethod) {
      history.push("/");
    }
  }, [history, playMethod, players]);


  if (!players || !playMethod) {
    return null;
  }

  const doSetGameSetting = (value) => {
    dispatch(
      {
        type: GAME_SETTING,
        player1: changeablePlayers[0] ? changeablePlayers[0].name : null,
        player1Avatar: changeablePlayers[0] ? changeablePlayers[0].avatar : null,
        player1won: changeablePlayers[0] ? changeablePlayers[0].gamesWon : null,
        player2: changeablePlayers[1] ? changeablePlayers[1].name : null,
        player2Avatar: changeablePlayers[1] ? changeablePlayers[1].avatar : null,
        player2won: changeablePlayers[1] ? changeablePlayers[1].gamesWon : null,
        player3: changeablePlayers[2] ? changeablePlayers[2].name : null,
        player3Avatar: changeablePlayers[2] ? changeablePlayers[2].avatar : null,
        player3won: changeablePlayers[2] ? changeablePlayers[2].gamesWon : null,
        player4: changeablePlayers[3] ? changeablePlayers[3].name : null,
        player4Avatar: changeablePlayers[3] ? changeablePlayers[3].avatar : null,
        player4won: changeablePlayers[3] ? changeablePlayers[3].gamesWon : null,
        startValue: start,
        playMethod: playMethod
      }
    );
    dispatch({ type: RESET_INIT });
    history.push("/play");
  };


  const array_move = (old_index, new_index) => {

    console.log(old_index + "_" + new_index)

    if (new_index >= changeablePlayers.length) {
      var k = new_index - changeablePlayers.length + 1;
      while (k--) {
        changeablePlayers.push(undefined);
      }
    }
    changeablePlayers.splice(new_index, 0, changeablePlayers.splice(old_index, 1)[0]);



    setChangeablePlayers(cloneDeep(changeablePlayers));
  };

  return (
    <div className="center800">
      <h1>Spielende</h1>
      <h2>Spiel: {playMethod} / {start}</h2>

      <br /><br />

      {players.map((player, index) => {

        let average = (start - player.rest) / player.gameThrowCount;
        average = average.toFixed(2);

        return (
          <div key={index}>


            <div className="resultWrapper">

              <span style={{ fontSize: "2em", fontWeight: "bold" }}>{player.name}</span>
              <br />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>
                      Geworfene Pfeile:
                    </td>
                    <td>
                      {player.gameThrowCount}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Geworfene Double:
                  </td>
                    <td>
                      {player.doubleThrowCount}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Geworfene Triple:
                   </td>
                    <td>
                      {player.tripleThrowCount}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Geworfene Punkte:
                   </td>
                    <td>
                      {start - player.rest}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dart Durchschnitt:
                   </td>
                    <td>
                      {average}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Gewonnene Spiele:
                   </td>
                    <td>
                      {player.gamesWon}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="resultWrapper">
              {player.winner &&
                <img draggable="false" src="images/pokal.png" alt="pokal" />
              }
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        )
      })}

      {changeablePlayers && changeablePlayers.length > 1 &&
        <div style={{ marginTop: "40px", marginBottom: "40px", fontSize: "1.5em" }} >
          <h2>Position ändern:</h2>

          {changeablePlayers && changeablePlayers.length > 1 && changeablePlayers.map((player, index) => {

            return <div key={index}>
              <div style={{ float: "left", width: "200px", marginTop: "20px" }}>
                {player.name}
              </div>
              {index > 0 &&
                <div className="arrow" onClick={() => array_move(index, index - 1)} >
                  &uarr;
            </div>}
              {!(index + 1 === changeablePlayers.length) &&
                <div className="arrow" onClick={() => array_move(index, index + 1)} >
                  &darr;
              </div>}
              <div style={{ clear: "both" }}></div>
            </div >
          })}
        </div>
      }



      <br /><br />
      <div className="actionButton" onClick={doSetGameSetting}>Spiel neu starten</div>
      <div className="actionButton" style={{ marginBottom: "60px" }} onClick={() => { history.push("/") }}>Zur Startseite</div>

    </div>




  );
}

export default Result;