import { combineReducers } from "redux";
import dartsReducer, { DARTS_UNDO, TOGGLE_DOUBLE, TOGGLE_TRIPLE } from './dartsReducer';
import undoable, { excludeAction } from 'redux-undo';


export default combineReducers({
    darts: undoable(dartsReducer, {
        undoType: DARTS_UNDO,
        filter: excludeAction([TOGGLE_DOUBLE, TOGGLE_TRIPLE])
    })
});