import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { THROW, TOGGLE_DOUBLE, TOGGLE_TRIPLE, DARTS_UNDO } from './dartsReducer'
import PlayerInfo from "./PlayerInfo";
import { ActionCreators } from "redux-undo";

const Start = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const {
    playMethod, players, double, triple, currentPlayer, gameFinished
  } = useSelector(state => state.darts.present);


  useEffect(() => {
    if (!players || !playMethod) {
      history.push("/");
    }
    else if (gameFinished) {
      history.push("/result");
    }
  }, [history, playMethod, players, gameFinished, dispatch]);

  useEffect( () => {
    dispatch( ActionCreators.clearHistory() );
  }, [dispatch] );

  if (!players || !playMethod) {
    return null;
  }


  const doThrow = (value) => {
    dispatch({
      type: THROW,
      value: value
    });
  };

  const doUndo = () => {
    dispatch({ type: DARTS_UNDO });
  };

  const toggleDouble = () => {
    dispatch({ type: TOGGLE_DOUBLE });
  };

  const toggleTriple = () => {
    dispatch({ type: TOGGLE_TRIPLE });
  };


  return (

    <div className="holePlayWrapper">


      <div className="numbersWrapper">

        <div style={{
          float: "left",
          width: "95%"
        }}>
          <div>
            <button className="button-round" onClick={() => {
              doThrow(1);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_1_100.png" alt="1" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(2);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_2_100.png" alt="2" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(3);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_3_100.png" alt="3" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(4);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_4_100.png" alt="4" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(5);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_5_100.png" alt="5" />
            </button>
          </div>
          <div>
            <button className="button-round" onClick={() => {
              doThrow(6);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_6_100.png" alt="6" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(7);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_7_100.png" alt="7" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(8);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_8_100.png" alt="8" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(9);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_9_100.png" alt="9" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(10);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_10_100.png" alt="10" />
            </button>
          </div>
          <div>
            <button className="button-round" onClick={() => {
              doThrow(11);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_11_100.png" alt="11" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(12);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_12_100.png" alt="12" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(13);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_13_100.png" alt="13" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(14);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_14_100.png" alt="14" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(15);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_15_100.png" alt="15" />
            </button>
          </div>
          <div>
            <button className="button-round" onClick={() => {
              doThrow(16);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_16_100.png" alt="16" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(17);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_17_100.png" alt="17" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(18);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_18_100.png" alt="18" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(19);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_19_100.png" alt="19" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(20);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_20_100.png" alt="20" />
            </button>
          </div>
          <div>
            <button disabled={triple ? true : false} className="button-round" onClick={() => {
              doThrow(25);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/green_circle_25_100.png" alt="25" />
            </button>
            <button className="button-round" onClick={() => {
              doThrow(0);
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/red_circle_0_100.png" alt="0" />
            </button>
            <button className="button-round" onClick={() => {
              toggleDouble();
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/x2_button_100.png" alt="x2" />
            </button>
            <button className="button-round" onClick={() => {
              toggleTriple();
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/x3_button_100.png" alt="x3" />
            </button>
            <button className="button-round" onClick={() => {
              doUndo();
            }}>
              <img style={{
                maxWidth: "100%",
                height: "auto"
              }} draggable="false" src="images/undo_button_100.png" alt="undo" />
            </button>

          </div>

        </div>
        <div style={{
          marginTop: "40px",
          width: "5%",
          float: "left"
        }}>
          {double ? <img style={{
            maxWidth: "100%",
            height: "auto"
          }} draggable="false" src="images/double.png" alt="double" /> : null}
          {triple ? <img style={{
            maxWidth: "100%",
            height: "auto"
          }} draggable="false" src="images/triple.png" alt="triple" /> : null}
        </div>
        <div style={{ clear: "both" }}></div>
      </div>


      <div className="playersWrapper">

        {players.map((player, index) => {
          return <PlayerInfo key={index} player={player} current={currentPlayer === index} />
        })}


      </div>


      <div style={{ clear: "both" }}></div>

      <div className="quitGameButton floatRight" type="button" onClick={() => setModalVisible(true)}>
        Spiel beenden
      </div>

      {modalVisible && (<div id="myModal" className="modal">

        <div className="modal-content">

          <div style={{ float: "left" }}>
            <p>Spiel wirklich beenden und zur Startseite zurück kehren? </p>
            <span className="quitGameButton floatLeft" type="button" onClick={() => { history.push("/") }}>
              Beenden
            </span>
            <span className="quitGameButton floatLeft" type="button" onClick={() => setModalVisible(false)}>
              Abbrechen
            </span>
          </div>

          <div style={{ clear: "both" }}></div>


        </div>

      </div>)}

    </div>);
}

export default Start;
