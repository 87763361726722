import React from 'react';

const PlayerInfo = ( { player, current } ) => {

  return (<div>


    <div className={current ? "currentPlayerThrow currentPlayerBackground" : "currentPlayerThrow otherPlayersBackground"}>

      <div className="imageAndName">

        <div style={{ fontWeight: "bold" }}>
          <img className="playerIcon" draggable="false" src={player.avatar === "MALE_1" ? "images/man_1.png" : player.avatar === "FEMALE_1" ? "images/woman_1.png" : player.avatar === "MALE_2" ? "images/man_2.png" : "images/woman_2.png"} align="top" alt="user"/>
          {player.name}
        </div>

        <span className="infoStatSmall">Rest: {player.rest},  &sum; {player.sum}</span>
        {player.finishWay && <div className="finishWaySmall"><img style={{
          width: "30px",
          height: "auto"
        }} draggable="false" src="images/finish_line.png"/> {player.finishWay}</div>}

      </div>


      <div style={{
        float: "right",
        width: "50%"
      }}>
        {player.overThrown ? <div className="overthrow">Überworfen</div> : (

          <table className="throw">
            <tbody>
            <tr>
              <td>
                {(player.firstThrow !== null && player.firstThrow !== undefined) ? player.firstThrow :
                  <span>&nbsp;</span>}
              </td>
              <td>
                {(player.secondThrow !== null && player.secondThrow !== undefined) ? player.secondThrow :
                  <span>&nbsp;</span>}
              </td>
              <td>
                {(player.thirdThrow !== null && player.thirdThrow !== undefined) ? player.thirdThrow :
                  <span>&nbsp;</span>}
              </td>
            </tr>
            </tbody>
          </table>)}
      </div>


      <div style={{ clear: "both" }}></div>

      <div className="infoStatBig"><span style={{
        width: "120px",
        display: "inline-block"
      }}>Rest: {player.rest}</span> <span style={{
        width: "80px",
        display: "inline-block"
      }}>&sum; {player.sum}</span> {player.sumLastThrow && <span>Letzter: {player.sumLastThrow}</span>}</div>

      {player.finishWay && <div className="infoStatBig"><img style={{
        width: "30px",
        height: "auto"
      }} draggable="false" src="images/finish_line.png"/> {player.finishWay}</div>}

    </div>


    <div style={{ clear: "both" }}></div>

  </div>);


}

export default PlayerInfo;
