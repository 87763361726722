import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import Start from './Start';
import Play from './Play';
import Result from './Result';
import { Provider } from 'react-redux'
import store from './store'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Start />
          </Route>
          <Route path="/play" exact >
            <Play />
          </Route>
          <Route path="/result" exact >
            <Result />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;